<template>
  <div>
    <v-row>
      <v-col cols="12" md="2">
        <v-autocomplete
          auto-select-first
          clearable
          v-model="filter.tahun"
          v-bind:label="$t('label.select year')"
          v-bind:items="comptTahun"
          item-text="title"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          auto-select-first
          clearable
          v-model="filter.bulan"
          v-bind:label="$t('label.select month')"
          v-bind:items="comptBulan"
          item-text="title"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="8">
        <v-autocomplete
          auto-select-first
          clearable
          v-model="filter.pengirim"
          v-bind:label="$t('label.select letter source')"
          v-bind:items="comptSender"
          item-text="name"
          item-value="name"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-text-field
          maxlength="200"
          clearable
          :clear-icon-cb="onClearClicked"
          :label="$t('label.memo-subject-optional')"
          v-model="filter.hal"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      v-bind:class="{ 'mb-4': comptData.length < 1 }"
    >
      <v-btn depressed color="primary" :loading="loading" @click="getReport">
        {{ $t("button.filter") }}
      </v-btn>
       <v-btn
        v-if="comptData && comptData.length > 0"
        depressed
        class="ml-1"
        color="cyan"
        :loading="excelLoading"
        @click="getExcel"
      >
        {{ $t("button.download excel") }}
      </v-btn>
    </v-row>
    <v-row class="mt-6" v-if="comptData && comptData.length > 0">
      <div class="responsive-table-container">
        <table class="table">
          <thead>
            <td class="column-xxs">No</td>
            <td class="column-sm">Nomor Surat</td>
            <td class="column-sm">Asal Surat</td>
            <td class="column-xs">Nomor Memo</td>
            <td class="column-md">Tanggal Memo</td>
            <td class="column-md">Perihal</td>
            <td class="column-sm">Tujuan Surat</td>
            <td class="column-xs">Isi Disposisi</td>
            <td class="column-xs">Tujuan Disposisi</td>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in comptData" :key="idx">
              <td class="text-right">{{ idx + 1 }}.</td>
              <td class="column-sm" style="white-space: normal;min-width:400px;">{{ cleanHtml(item.nomor_surat_eksternal) }}</td>
              <td>{{ item.lembaga_pengirim_surat }}</td>
              <td class="column-sm">{{ item.nomor }}</td>
              <td>{{ item.tgl_memo }}</td>
              <td>{{ item.hal }}</td>
              <td>{{ item.tujuan_surat }}</td>
              <td>{{ cleanNull(item.isi_disposisi) +' '+ cleanNull(item.pesan_disposisi)}}</td>
              <td>{{ item.tujuan_disposisi }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-row>
  </div>
</template>
<script>
import { http } from "@/http/http";
import { mapState } from "vuex";

const currYear = new Date().getFullYear();
const currMonth = new Date().getMonth() + 1;

export default {
  name: "report-eksternal",
  data: () => ({
    listTahun: null,
    listBulan: null,
    listSender: null,
    listReport: [],
    search: "",
    loading: false,
    excelLoading: false,
    loader: null,
    filter: {
      bulan: currMonth,
      tahun: currYear,
      pengirim: "",
      hal: "",
    },
  }),
  created() {
    this.getPageRef();
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  computed: {
    ...mapState("user",["currentUser"]),
    comptTahun() {
      if (!this.listTahun) return [];
      return this.listTahun;
    },
    comptBulan() {
      if (!this.listBulan) return [];
      return this.listBulan;
    },
    comptSender() {
      if (!this.listSender) return [];
      return this.listSender;
    },
    btnDisabled() {
      if (!this.filter.bulan || !this.filter.tahun || this.loading) return true;
      return false;
    },
    comptData() {
      if (!this.listReport) return [];
      return this.listReport;
    },
    namaUnitBagian(){
      if (!this.currentUser) return '';
      return this.currentUser.kode_unit  === '00' ? this.currentUser.nama_unit + '-'+ this.currentUser.nama_bagian : this.currentUser.nama_unit;
    }
  },
  methods: {
    getPageRef() {
      //  http://localhost:9000/api/report/inbox
      http.get("report/incoming").then((response) => {
        if (response) {
          this.listSender = response.data.institutions;
          this.listBulan = response.data.bulan;
          this.listTahun = response.data.tahun;
        }
      });
    },
    getReport() {
      if (this.loading) return;
      this.loading = true;

      http
        .get(
          `report/incoming-report?bulan=${this.filter.bulan}&tahun=${
            this.filter.tahun
          }&pengirim=${this.filter.pengirim ? this.filter.pengirim : ""}&hal=${
            this.filter.hal ? this.filter.hal : ""
          }`
        )
        .then((response) => {
          if (response) {
            this.listReport = response.data;
            this.loading = false;
          }
        });
    },
    getExcel(){
      if (this.excelLoading) return;
      this.excelLoading = true;

      http
        .get(
          `report/incoming-report-export?bulan=${this.filter.bulan}&tahun=${
            this.filter.tahun
          }&pengirim=${this.filter.pengirim ? this.filter.pengirim : ""}&hal=${
            this.filter.hal ? this.filter.hal : ""
          }`, { responseType: "blob" }
        )
        .then((response) => {
         if (response) {
            // this.listReport = response.data;
            // this.loading = false;
            const url = URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.ms-excel",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", 'SURAT_EKSTERNAL_'+this.namaUnitBagian+'_'+this.filter.bulan+'_'+this.filter.tahun+'.xlsx');
            document.body.appendChild(link);
            this.excelLoading = false;
            link.click();
          }
        });
    },
    onClearClicked() {
      this.filter.hal = "";
    },
     cleanNull(data) {
      if (!data) return "";
      return data;
    },
    cleanHtml(strInputCode) {
      return strInputCode.replace(/<\/?[^>]+(>|$)/g, "");
    } 
  },
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.standar-column {
  min-width: 250px;
}
.date-column {
  min-width: 150px;
}
.penerima-disposisi {
  min-width: 300px;
}
.title-column {
  min-width: 360px;
}

.responsive-table-container {
  width: 100%;
  max-width: 100%;
  max-height: 300px;
  overflow: auto;
}
.table {
  border-collapse: collapse;
}
.table td {
  border: 1px solid #999;
  padding: 0.3rem 0.5rem;
  white-space: nowrap;
}
.table thead {
  background-color: #666;
  color: #ddd;
  font-weight: 600;
}
.column-xxs {
  width: 50px;
}
.column-xs {
  width: 200px;
}
.column-sm {
  width: 600px !important;
}
.column-md {
  width: 200px;
}
.column-lg {
  width: 300px;
}
</style>