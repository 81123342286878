<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <v-card elevation="2">
            <v-card-text class="pa-5">
              <v-row>
                <v-col cols="12" md="8">
                  <h3 class="title text--darken-2 font-weight-regular">
                    {{ `${$t("card.report")} ${reportKind ? '- '+reportKind: '' }` }}
                  </h3>
                  <h6 class="subtitle-2 font-weight-light mb-2">
                    {{
                      $t(
                        "label.select report type year and month must be set before click filter button"
                      )
                    }}
                  </h6>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="reportKind"
                    :items="reportList"
                    :label="$t('label.report kind')"
                  ></v-select>
                </v-col>
              </v-row>

              <v-card-text>
                <ReportInbox v-if="reportKind === 'Memo Masuk'" />
                <ReportTerkirim v-if="reportKind === 'Memo Terkirim'" />
                <ReportEksternal v-if="reportKind === 'Surat Masuk Eksternal'" />
                <ReportDisposition v-if="reportKind === 'Disposisi Atasan'" />
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-flex>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import ReportInbox from "./ReportInbox.vue";
import ReportTerkirim from "./ReportTerkirim.vue";
import ReportEksternal from "./ReportEksternal.vue";
import ReportDisposition from "./ReportDisposition.vue";

export default {
  name: "report-inbox",
  components: {
    ReportInbox,
    ReportTerkirim,
    ReportEksternal,
    ReportDisposition
  },
  data: () => ({
    reportKind: null,
  }),
  computed: {
    ...mapState("user",["currentUser"]),
    reportList() {
      if (!this.currentUser) return [];
      if (this.currentUser.kode_bagian === 'BAG237') {
        return [
          "Surat Masuk Eksternal",
          "Memo Masuk",
          "Memo Terkirim",
          "Disposisi Atasan",
        ];
      }
         return [
          "Memo Masuk",
          "Memo Terkirim",
          "Disposisi Atasan",
        ];
    },
  },
};
</script>
