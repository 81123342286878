<template>
  <div>
    <v-row>
      <v-col cols="12" md="2">
        <v-autocomplete
          auto-select-first
          clearable
          v-model="filter.tahun"
          v-bind:label="'* ' + $t('label.select year')"
          v-bind:items="comptTahun"
          item-text="title"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          auto-select-first
          clearable
          v-model="filter.bulan"
          v-bind:label="'* ' + $t('label.select month')"
          v-bind:items="comptBulan"
          item-text="title"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          v-model="filter.jenis"
          :items="reportList"
          :label="$t('label.report kind')"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          maxlength="100"
          clearable
          :clear-icon-cb="onClearClicked"
          :label="$t('label.memo-subject-optional')"
          v-model="filter.hal"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      v-bind:class="{ 'mb-4': comptData.length < 1 }"
    >
      <v-btn depressed color="primary" :loading="loading" @click="getReport">
        {{ $t("button.filter") }}
      </v-btn>
       <v-btn
        v-if="comptData && comptData.length > 0"
        depressed
        class="ml-1"
        color="cyan"
        :loading="excelLoading"
        @click="getExcel"
      >
        {{ $t("button.download excel") }}
      </v-btn>
    </v-row>
    <v-row class="mt-6" v-if="comptData && comptData.length > 0">
      <div class="responsive-table-container" style="max-height: 370px">
        <table class="table">
          <thead>
            <td class="column-xxs">No</td>
            <td class="column-sm">Nomor Memo</td>
            <td class="column-sm">Jenis Memo</td>
            <td class="column-xs">Tgl Pengiriman</td>
            <td class="column-xs">Hal Memo</td>
            <td class="column-xs">Pemberi Disposisi</td>
            <td class="column-md">Tgl Disposisi</td>
            <td class="column-md">Level Urgensi</td>
            <td class="column-md">Pesan Disposisi</td>
            <td class="column-md">Komentar</td>
            <td class="column-md">Penerima Disposisi</td>
            <td class="column-md">Tujuan Disposisi Selanjutnya</td>
            <td class="column-md">Komentar Disposisi Selanjutnya</td>
          </thead>
          <tbody>
            <template v-for="(item, idx) in comptData">
            <tr v-bind:key="idx" v-if="!item.induk_id">
              <td class="text-right">{{ idx + 1 }}.</td>
              <td>{{ item.nomor }}</td>
              <td>{{ item.jenis_memo }}</td>
              <td>{{ item.tgl_pengiriman }}</td>
              <td>{{ item.hal }}</td>
              <td>{{ item.pemberi_disposisi }}</td>
              <td>{{ item.tgl_disposisi }}</td>
              <td>{{ item.level_urgensi }}</td>
              <td>{{ item.pesan_disposisi }}</td>
              <td>{{ item.comment }}</td>
              <td>{{ item.penerima_disposisi }}</td>
              <td></td>
              <td></td>
            </tr>
            </template>
          </tbody>
        </table>
      </div>
    </v-row>
  </div>
</template>
<script>
import { http } from "@/http/http";
import { mapState } from "vuex";

const currYear = new Date().getFullYear();
const currMonth = new Date().getMonth() + 1;

export default {
  name: "report-disposition",
  data: () => ({
    listTahun: null,
    listBulan: null,
    listReport: [],
    search: "",
    loading: false,
    excelLoading: false,
    reportKind: "Semua",

    loader: null,
    filter: {
      bulan: currMonth,
      tahun: currYear,
      jenis: "Semua",
      hal: "",
    },
  }),
  created() {
    this.getPageRef();
  },
  watch: {
    // loader() {
    //   const l = this.loader;
    //   this[l] = !this[l];
    //   setTimeout(() => (this[l] = false), 3000);
    //   this.loader = null;
    // },
  },
  computed: {
    ...mapState("user", ["currentUser"]),
    reportList() {
      let arrUnitHasPermit = ["00", "01", "02", "03"];
      if (!this.currentUser) return [];
      if (arrUnitHasPermit.includes(this.currentUser.kode_unit)) {
        return ["Memo Biasa", "Memo BOM", "Izin Prinsip", "Surat Eksternal"];
      }
       return ["Memo Biasa", "Memo BOM", "Surat Eksternal"];
    },
    comptTahun() {
      if (!this.listTahun) return [];
      return this.listTahun;
    },
    comptBulan() {
      if (!this.listBulan) return [];
      return this.listBulan;
    },
    comptSender() {
      if (!this.listSender) return [];
      return this.listSender;
    },
    btnDisabled() {
      if (!this.filter.bulan || !this.filter.tahun || this.loading) return true;
      return false;
    },
    comptData() {
      if (!this.listReport) return [];
      return this.listReport;
    },
    namaUnitBagian(){
      if (!this.currentUser) return '';
      return this.currentUser.kode_unit  === '00' ? this.currentUser.nama_unit + '-'+ this.currentUser.nama_bagian : this.currentUser.nama_unit;
    }
  },
  methods: {
    getPageRef() {
      //  http://localhost:9000/api/report/inbox
      http.get("report/disposition").then((response) => {
        if (response) {
          this.listBulan = response.data.bulan;
          this.listTahun = response.data.tahun;
        }
      });
    },
    getReport() {
      if (this.loading) return;
      this.loading = true;

      http
        .get(
          `report/disposition-report?bulan=${this.filter.bulan}&tahun=${
            this.filter.tahun
          }&jenis=${this.filter.jenis}&hal=${
            this.filter.hal ? this.filter.hal : ""
          }`
        )
        .then((response) => {
          if (response) {
            this.listReport = response.data;
            this.loading = false;
          }
        });
    },
     getExcel() {
      if (this.excelLoading) return;
      this.excelLoading = true;

      http
        .get(
           `report/sent-report-export?bulan=${this.filter.bulan}&tahun=${
            this.filter.tahun
          }&jenis=${this.filter.jenis}&hal=${
            this.filter.hal ? this.filter.hal : ""
          }`,
          { responseType: "blob" }
        )
        .then((response) => {
          if (response) {
            // this.listReport = response.data;
            // this.loading = false;
            const url = URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.ms-excel",
              })
            );
            const link = document.createElement("a");
            link.href = url;
             link.setAttribute("download", 'MEMO_TERKIRIM_'+this.namaUnitBagian+'_'+this.filter.bulan+'_'+this.filter.tahun+'.xlsx');
            document.body.appendChild(link);
            this.excelLoading = false;
            link.click();
          }
        });
    },
    onClearClicked() {
      this.filter.hal = "";
    },
    cleanNull(data) {
      if (!data) return "";
      return data;
    },
  },
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.standar-column {
  min-width: 250px;
}
.date-column {
  min-width: 150px;
}
.penerima-disposisi {
  min-width: 300px;
}
.title-column {
  min-width: 360px;
}
</style>